
export const imagesTurboHelp = [
  {
    original: "photos/turbo/01-001.jpg",
    thumbnail: "photos/turbo/01-001.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/01-004.jpg",
    thumbnail: "photos/turbo/01-004.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/01-002.jpg",
    thumbnail: "photos/turbo/01-002.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/turbo/01-003.jpg",
    thumbnail: "photos/turbo/01-003.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/01.jpg",
    thumbnail: "photos/help/01.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/02.jpg",
    thumbnail: "photos/help/02.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  },
  {
    original: "photos/help/03.jpg",
    thumbnail: "photos/help/03.jpg",
    originalClass: "gallery-photo-big",
    thumbnailClass: "gallery-photo-small",
  }
];
